<template>
  <div class="container my-5 pb-3" style="padding-top: 7rem;">
        <div class="card shadow-sm d-flex flex-column justify-content-center">
            <div class="card py-3 px-4">
                <h4 class="fw-bold text-center" style="font-size: medium;">Track my shipment</h4>
            </div>
            <form @submit.prevent="getSRN">
                <div class="card py-3 px-4">
                    <div class="w-100">
                        <div class="d-flex flex-column flex-md-row justify-content-center align-items-center gap-4 px-2 px-md-5">
                            <span class="fw-bold" style="font-size: larger;">Enter your SRN</span>
                            <div id="srn" class="d-flex">
                                <label for="search" class="border border-end-0 rounded-start py-2 px-3">
                                    <img src="../assets/img/magnifying-glass.png" alt="magnifying-glass">
                                </label>
                                <input v-model="srn_value" id="search" type="text" placeholder="Enter SRN" class="w-100 border border-start-0 rounded-end py-2 input-outline" name="srn" required>
                            </div>
                            <div class="search-loader me-2" v-show="$store.state.searching"></div>
                            <button type="submit" class="bg-theme text-white py-2 rounded border-0 px-5" style="min-width: 150px;">Search</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div id="consdf" class="d-none d-flex flex-column justify-content-center gap-4"> 
            <div id="info">
                <div class="position-relative w-100 d-flex flex-column mt-3 d-flex flex-column gap-4 mb-5">
                    <span class="fs-3 fw-bold px-1 text-center">Yahoo! Your shipment is on-going</span>
                    <div id="tracking-card" class="card shadow-sm d-flex flex-column justify-content-center w-50 mx-auto">
                        <div class="card py-3 px-4">
                            <h4 class="fw-bold text-center" style="font-size: medium;">Tracking Number</h4>
                        </div>
                        <div class="card py-3 px-4">
                            <h2 class="text-theme fw-bold text-center">{{ logistic_data.srn }}</h2>
                        </div>
                    </div> 
                </div>
                <div class="card shadow-sm mx-auto mb-3 w-100">
                    <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100 position-relative">
                        <div class="row w-100 gap-2 gap-md-0">
                            <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">Item Description</h2>
                            <span class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden">{{ logistic_data.items }}</span>
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="position-relative mt-4">
                        <div class="circle"></div>
                        <div class="line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 postion-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(0)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100 position-relative">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">Documents Received</h2>
                                <span id="doc-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.doc_desc }}</span>
                                <span id="doc-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.doc_date || logistic_data.doc_time ? logistic_data.doc_date+' | '+logistic_data.doc_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="doc-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.doc_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="doc-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.doc_date+' | '+logistic_data.doc_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="position-relative">
                        <div class="circle mt-4"></div>
                        <div class="line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 position-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(1)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">ETD of the Vessel</h2>

                                <span id="etd-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.etd_desc }}</span>
                                <span id="etd-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.etd_date || logistic_data.etd_time ? logistic_data.etd_date+' | '+logistic_data.etd_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="etd-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.etd_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="etd-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.etd_date+' | '+logistic_data.etd_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="position-relative">
                        <div class="circle mt-4"></div>
                        <div class="line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 position-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(2)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">ETA of the Vessel</h2>
                                <span id="eta-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.eta_desc }}</span>
                                <span id="eta-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.eta_date || logistic_data.eta_time ? logistic_data.eta_date+' | '+logistic_data.eta_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="eta-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.eta_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="eta-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.eta_date+' | '+logistic_data.eta_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="position-relative">
                        <div class="circle mt-4"></div>
                        <div class="line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 position-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(3)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">ETB of the Vessel</h2>
                                <span id="etb-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.etb_desc }}</span>
                                <span id="etb-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.etb_date || logistic_data.etb_time ? logistic_data.etb_date+' | '+logistic_data.etb_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="etb-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.etb_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="etb-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.etb_date+' | '+logistic_data.etb_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="position-relative">
                        <div class="circle mt-4"></div>
                        <div class="line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 position-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(4)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">For Shipping Line Endorsement</h2>
                                <span id="endorsement-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.endorsement_desc }}</span>
                                <span id="endorsement-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.endorsement_date || logistic_data.endorsement_time ? logistic_data.endorsement_date+' | '+logistic_data.endorsement_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="endorsement-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.endorsement_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="endorsement-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.endorsement_date+' | '+logistic_data.endorsement_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="position-relative">
                        <div class="circle mt-4"></div>
                        <div class="line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 position-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(5)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">For Customs Processing</h2>
                                <span id="customs-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.customs_desc }}</span>
                                <span id="customs-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.customs_date || logistic_data.customs_time ? logistic_data.customs_date+' | '+logistic_data.customs_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="customs-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.customs_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="customs-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.customs_date+' | '+logistic_data.customs_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="position-relative">
                        <div class="circle mt-4"></div>
                        <div class="line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 position-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(6)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">For Final Assessment</h2>
                                <span id="assessment-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.assessment_desc }}</span>
                                <span id="assessment-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.assessment_date || logistic_data.assessment_time ? logistic_data.assessment_date+' | '+logistic_data.assessment_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="assessment-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.assessment_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="assessment-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.assessment_date+' | '+logistic_data.assessment_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="position-relative">
                        <div class="circle mt-4"></div>
                        <div class="line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 position-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(7)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">Paid Duties</h2>

                                <span id="paidDuties-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.paidDuties_desc }}</span>
                                <span id="paidDuties-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.paidDuties_date || logistic_data.paidDuties_time ? logistic_data.paidDuties_date+' | '+logistic_data.paidDuties_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="paidDuties-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.paidDuties_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="paidDuties-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.paidDuties_date+' | '+logistic_data.paidDuties_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="position-relative">
                        <div class="circle mt-4"></div>
                        <div class="line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 position-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(8)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">For Arrastre/Wharfage Payment</h2>
                                <span id="arrastre-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.arrastre_desc }}</span>
                                <span id="arrastre-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.arrastre_date || logistic_data.arrastre_time ? logistic_data.arrastre_date+' | '+logistic_data.arrastre_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="arrastre-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.arrastre_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="arrastre-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.arrastre_date+' | '+logistic_data.arrastre_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="position-relative">
                        <div class="circle mt-4"></div>
                        <div class="line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 position-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(9)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">TABS Booking</h2>
                                <span id="tabs-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.tabs_desc }}</span>
                                <span id="tabs-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.tabs_date || logistic_data.tabs_time ? logistic_data.tabs_date+' | '+logistic_data.tabs_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="tabs-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.tabs_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="tabs-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.tabs_date+' | '+logistic_data.tabs_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="position-relative">
                        <div class="circle mt-4"></div>
                        <div class="line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 position-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(10)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">For Gatepass</h2>
                                <span id="gatepass-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.gatepass_desc }}</span>
                                <span id="gatepass-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.gatepass_date || logistic_data.gatepass_time ? logistic_data.gatepass_date+' | '+logistic_data.gatepass_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="gatepass-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.gatepass_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="gatepass-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.gatepass_date+' | '+logistic_data.gatepass_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="position-relative">
                        <div class="circle mt-4"></div>
                        <div class="end-line"></div>
                    </div>
                    <div class="card shadow-sm ms-2 ms-md-5 mb-3 w-100 position-relative">
                        <button name="primaries" type="button" class="d-none position-absolute bg-transparent w-100 h-100 border-0" @click="open_close(11)" style="z-index: 10;"></button>
                        <div class="card py-3 px-2 ps-md-5 pe-md-4 w-100">
                            <div class="row w-100 gap-2 gap-md-0">
                                <h2 class="fw-bold fs-large col-md-4 col-lg-4 col-xl-5">For Delivery</h2>
                                <span id="delivery-desc-primary" class="text-muted col-md-5 col-lg-5 col-xl-5 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.delivery_desc }}</span>
                                <span id="delivery-date-primary" class="text-muted col-md-3 col-lg-3 col-xl-2 overflow-hidden" style="text-overflow: ellipsis; white-space:nowrap;">{{ logistic_data.delivery_date || logistic_data.delivery_time ? logistic_data.delivery_date+' | '+logistic_data.delivery_time : '' }}</span>
                            </div>
                            <span name="expand" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-down"></i></span>
                            <span name="close" class="d-none bg-transparent border-0 position-absolute end-0 pe-3"><i class="text-theme bi bi-chevron-up"></i></span>
                            <div name="info-tab" class="d-none d-flex flex-column gap-2 py-3">
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Description</span>
                                    <span id="delivery-desc-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.delivery_desc }}</span>
                                </div>
                                <div class="border border-theme p-2 bg-light d-flex flex-column">
                                    <span class="fw-bold">Date and Time</span>
                                    <span id="delivery-date-secondary" class="px-2" style="text-indent: 50px;">{{ logistic_data.delivery_date+' | '+logistic_data.delivery_time }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div id="error" class="d-none d-flex flex-column align-items-center text-center mt-5 mx-auto">
            <img src="../assets/img/icons/truck.png" alt="truck" class="w-50 opacity-75" style="object-fit: contain;">
            <h2 class="w-75 text-muted mt-4">This shipment has not yet started</h2>
        </div>
    </div>
</template>

<style>
.search-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
}

.search-loader:before {
  content: "";
  position: absolute;
  top: calc(50% - 0px);
  left: calc(50% - 30px);
  border: 6px solid rgb(0, 0, 0, 0.2);
  border-top-color: #6E111D;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
import { format } from 'date-fns/fp'

export default {
  name: 'TrackShipment',
  data() {
    return {
      hasError: false,
      position: -1,
      srn: false,
      srn_value: '',
      circleArray: document.getElementsByClassName('circle'),
      expand_buttons: document.getElementsByName('expand'),
      close_buttons: document.getElementsByName('close'),
      info_tabs: document.getElementsByName('info-tab'),
      primaries: document.getElementsByName('primaries'),
      logistic_data: {}
    }
  },
  async mounted() {
    document.getElementById("error").classList.toggle("d-none");
    // Inititalized things
    for(var i = 0; i < this.circleArray.length; i++) {
        this.circleArray[i].classList.add('bg-muted');
    }

    if(this.$route.query.srn) {
        this.srn_value = this.$route.query.srn
        await this.getSRN()
        return
    }

    this.dom_initialize()
  },
  methods: {
    dom_initialize() {
      if(this.hasError) {
        document.getElementById("error").classList.remove("d-none");
        document.getElementById("info").classList.add("d-none");
      }

      if(this.srn) {
        if(!this.hasError) {
          if(this.position < 0) {
            document.getElementById("error").classList.remove("d-none");
            return false;
          }
          for(var j = 0; j < this.circleArray.length; j++) {
            this.circleArray[j].classList.remove('bg-theme');
            this.circleArray[j].classList.add('bg-muted');

              
            this.info_tabs[j].classList.add('d-none');
            this.close_buttons[j].classList.add('d-none');
            this.expand_buttons[j].classList.add('d-none');
          }

            document.getElementById("consdf").classList.remove("d-none");
            document.getElementById("error").classList.add("d-none");
            document.getElementById("info").classList.remove("d-none");
          
          for(var d = 0; d <= this.position; d++) {
            this.circleArray[d].classList.remove('bg-muted');
            this.circleArray[d].classList.add('bg-theme');
            this.expand_buttons[d].classList.remove('d-none');
            this.primaries[d].classList.remove('d-none');
          }

          this.circleArray[this.position].scrollIntoView({
            block: "center",
          });

          
          return 0;
        }
      }  
    },
    open_close(i) {
      this.info_tabs[i].classList.toggle('d-none');
      this.close_buttons[i].classList.toggle('d-none');
      this.expand_buttons[i].classList.toggle('d-none');
    },
    async getSRN() {
      await this.$store.dispatch('getSRN', this.srn_value).then(() => {
        this.message = this.$store.state.message
        if(this.message === 'Not Found') {
          this.hasError = true
          this.dom_initialize()
          return
        }

        this.logistic_data = this.$store.state.logistic_data

        const date = '(.*?)(?<=_)date'
        const time = '(.*?)(?<=_)time'

        var date_re = new RegExp(date), date_key;
        for (date_key in this.logistic_data) {
            if(date_re.test(date_key) && this.logistic_data[date_key]) {
              this.logistic_data[date_key] = format('MMM. dd, yyyy eee', new Date(this.logistic_data[date_key]))
            }
        }
        var time_re = new RegExp(time), time_key;
        for (time_key in this.logistic_data) {
          if(time_re.test(time_key) && this.logistic_data[time_key]) {

            var hours = this.logistic_data[time_key].hours;
            var mins = this.logistic_data[time_key].minutes;
            var normal_time = new Date('', '', '', hours, mins)

            this.logistic_data[time_key] = format('hh:mm a', normal_time)
          }
        }
        this.position = this.logistic_data.last_edited
        this.hasError = false
        this.srn = true
        this.dom_initialize()
      })
    }
  }
}
</script>